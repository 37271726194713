import HoverBoxComponent from '@wix/thunderbolt-elements/src/components/MediaContainers/HoverBox/viewer/HoverBox';


const HoverBox = {
  component: HoverBoxComponent
};


export const components = {
  ['HoverBox']: HoverBox
};


// temporary export
export const version = "1.0.0"
